import axios from "axios";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Typography,
  Box,
  Stack,
  Grid,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { UserContext } from "../../context/UserContext";
import FlowCard from "./FlowCard";
import MiNiFirmReport from "../Dashboard/FirmListing/MiNiFirmReport";
import { styled } from "@mui/material/styles";
import InitialContact from "./CaseFlowDetail/InitialContact";
import ThriveModal from "../common/ThriveModal";
import DiscoveryQuestions from "./CaseFlowDetail/DiscoveryQuestions";
import BusinessNeedsAssessment from "./CaseFlowDetail/BusinessNeedsAssessment";
import Interaction from "./Interaction";
import BusinessActionPlan from "./CaseFlowDetail/BusinessActionPlan";
import Referral from "./Referral";
import {
  CaseType,
  defaultCase,
  UserType,
  defaultUser,
  ReferralType,
} from "../../interfaces";
import ContentChange from "./CaseFlowDetail/ContentChange";
import ReassignClient from "./ReassignClient";
import ClientReferralSurvey from "./ClientReferralSurvey";
import Confirm from "./Confirm";
import NoticeDialog from "../common/NoticeDialog";
import EmailViewer from "../common/EmailViewer";
import EmailEditor from "../common/EmailEditor";
import SurveyResultViewer from "../common/SurveyResultViewer";
import Add2ndBfc from "./Add2ndBfc";
import MergeCase from "./MergeCase";
import { ProviderReferralClientSurvey } from "../common/EmailBodies/ProviderReferralClientSurvey";
import { ProviderReferralProviderSurvey } from "../common/EmailBodies/ProviderReferralProviderSurvey";
import { DirectEmailEmptyBody } from "../common/EmailBodies/DirectEmailEmptyBody";
import { ResourceReferralNotice } from "../common/EmailBodies/ResourceReferralNotice";
import { Provider2hoursReferral } from "../common/EmailBodies/Provider2hoursReferral";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "1px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "td, th": {
    border: 0,
  },
}));

const ignoredActivityTypes = [
  "Case Created",
  "Secondary BFC Assigned",
  "Unassign Secondary BFC",
  "Referral",
  "Case Merged",
  "Case Closed",
];

const Case = () => {
  const caseTypeMap = (key: string) => {
    if (key === "Initial Contact") {
      return "Initiate Contact";
    } else if (key === "Initial Contact (edit)") {
      return "Initiate Contact (edit)";
    } else {
      return key;
    }
  };

  const { user }: { user: UserType } = useContext(UserContext);

  const [dataFlag, setDataFlag] = useState<boolean>(false);
  const [referrals, setReferrals] = useState<ReferralType[]>([]);
  const [referralNoticeContent, setReferralNoticeContent] = useState({});
  const [providerReferralNoticeOpen, setProviderReferralNoticeOpen] =
    useState(false);
  const [openActivityID, setOpenActivityID] = useState<string>("");

  const [cardOpen, setCardOpen] = useState<boolean>(false);
  const [activityCardOpen, setActivityCardOpen] =
    React.useState<boolean>(false);
  const [referralNoticeOpen, setReferralNoticeOpen] =
    React.useState<boolean>(false);
  const [reassignClientOpen, setReassignClientOpen] =
    React.useState<boolean>(false);
  const [addBfcOpen, setAddBfcOpen] = React.useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<string>("");
  const [confirmAction, setConfirmAction] = useState<
    ((id: string) => void) | null
  >(null);
  const [openReferral, setOpenReferral] = useState({});
  const [openCardTitle, setOpenCardTitle] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const caseID: string = queryParams.get("id") as string;
  const [theCase, setTheCase] = useState<CaseType>(defaultCase);
  const [creator, setCreator] = useState<UserType>(defaultUser);
  const [secondaryBfc, setSecondaryBfc] = useState<UserType>(defaultUser);
  const [firm, setFirm] = useState<any>({});
  const [editedFirm, setEditedFirm] = useState({});
  const [editedGrant, setEditedGrant] = useState({});
  const [activity, setActivity] = useState<any>([]);
  const [initialMap, setInitialMap] = useState<{
    [key: string]: { init: string; full: string };
  }>({});
  const [providerSurveyOpen, setProviderSurveyOpen] = useState<boolean>(false);
  const [clientSurveyOpen, setClientSurveyOpen] = useState<boolean>(false);
  const [directEmailOpen, setDirectEmailOpen] = useState<boolean>(false);
  const [mergeCaseOpen, setMergeCaseOpen] = useState<boolean>(false);
  const [selectedProvider, setSelectedProvider] = useState<any>({});
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };
  const navigate = useNavigate();

  const closeCase = (id: string) => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/cases/updateStatus`,
        {
          id: id,
          status: "completed",
        },
        axiosConfig
      )
      .then((res) => {
        navigate("/dashboard");
      });
  };

  const deleteCase = (id: string) => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/cases/updateStatus`,
        {
          id: id,
          status: "deleted",
        },
        axiosConfig
      )
      .then((res) => {
        navigate("/dashboard");
      });
  };

  const reopenCase = (id: string) => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/cases/updateStatus`,
        {
          id: id,
          status: "open",
        },
        axiosConfig
      )
      .then((res) => {
        navigate("/dashboard");
      });
  };

  const getUsers = (theCase: any) => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/user?id=${theCase.user}`,
        axiosConfig
      )
      .then((res) => {
        setCreator(res.data.user);
      });

    if (theCase.user_2nd) {
      axios
        .get(
          `${process.env.REACT_APP_API_DOMAIN}/user?id=${theCase.user_2nd}`,
          axiosConfig
        )
        .then((res) => {
          setSecondaryBfc(res.data.user);
        });
    }
  };

  const getFirmById = (id: string) => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/firms/id?id=${id}`, axiosConfig)
      .then((res) => {
        setFirm(res.data.firm);
        setEditedFirm(res.data.firm);
        setEditedGrant(res.data.firm.grants);
      });
  };

  const getReferrals = (activity: any) => {
    let uniqueReferrals: any[] = [];
    activity.map((item: any) => {
      if (!uniqueReferrals.includes(item.referral_id)) {
        uniqueReferrals.push(item.referral_id);
      }
      return true;
    });
    return uniqueReferrals;
  };

  const getInitialMap = (ids: string[]) => {
    let tempInitialMap: { [key: string]: { init: string; full: string } } = {
      HIS: { init: "HIS", full: "HIS" },
    };
    const clean_ids = ids.filter((element: any) => {
      // Keep only elements that are not null, undefined, or empty strings
      return (
        element !== null &&
        element !== undefined &&
        element !== "" &&
        element !== "HIS"
      );
    });
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/user?ids=${clean_ids.join(",")}`,
        axiosConfig
      )
      .then((res) => {
        res.data.user.map((user: any) => {
          tempInitialMap[user._id as string] = {
            init: user["firstName"][0] + user["lastName"][0],
            full: user["firstName"] + " " + user["lastName"],
          };
          return null;
        });
        setInitialMap(tempInitialMap);
      });
  };

  const compareByDate = (a: any, b: any) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  };

  const getActivity = (id: string) => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/activity?case_id=${id}`,
        axiosConfig
      )
      .then((res) => {
        // console.log(res.data.activity);
        const sortedActivity = res.data.activity.sort(compareByDate);
        setActivity(sortedActivity);
        const initials: string[] = Array.from(
          new Set(res.data.activity.map((item: any) => item.user_id))
        );

        if (initials.length > 1) {
          getInitialMap(initials);
        }
        setReferrals(
          getReferrals(
            res.data.activity.filter((item: any) => {
              return (
                item.activity_type === "Referral" ||
                item.activity_type === "Provider Referral Initiated"
              );
            })
          )
        );
      });
  };

  React.useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/cases?id=${caseID}`,
        axiosConfig
      )
      .then((res) => {
        setTheCase(res.data.case);
        getUsers(res.data.case);
        getFirmById(res.data.case.firm);
        getActivity(res.data.case._id);
      });
  }, [dataFlag]);

  const ActivityModalContent = (id: string) => {
    const selectedActivity = activity.find((activityItem: any) => {
      return activityItem.activity_id === id;
    });
    // console.log(selectedActivity);
    if (selectedActivity) {
      switch (selectedActivity["activity_type"]) {
        case "Initial Contact (edit)":
          return (
            <ContentChange
              setCardOpen={setActivityCardOpen}
              caseID={caseID}
              activity={selectedActivity}
              dataFlag={dataFlag}
              setDataFlag={setDataFlag}
            ></ContentChange>
          );
        case "Initial Contact":
          return (
            <InitialContact
              setCardOpen={setActivityCardOpen}
              caseID={caseID}
              activity={activity}
              dataFlag={dataFlag}
              setDataFlag={setDataFlag}
            ></InitialContact>
          );

        // case "Discovery Questions":
        //   return (
        //     <DiscoveryQuestions
        //       setCardOpen={setActivityCardOpen}
        //       case={theCase}
        //       activity={activity}
        //       dataFlag={dataFlag}
        //       setDataFlag={setDataFlag}
        //     ></DiscoveryQuestions>
        //   );

        case "Client Referral Survey (by Client)":
          return (
            <ClientReferralSurvey
              setSurveyOpen={setActivityCardOpen}
              activity={selectedActivity}
            ></ClientReferralSurvey>
          );

        case "B2B Client Survey (by Client)":
          return (
            <SurveyResultViewer
              setSurveyOpen={setActivityCardOpen}
              activity={selectedActivity}
              title={"B2B Client Survey Result"}
            ></SurveyResultViewer>
          );

        case "B2B Provider Survey (by Provider)":
          return (
            <SurveyResultViewer
              setSurveyOpen={setActivityCardOpen}
              activity={selectedActivity}
              title={"B2B Provider Survey Result"}
            ></SurveyResultViewer>
          );

        // case "Discovery Questions (edit)":
        //   return (
        //     <ContentChange
        //       setCardOpen={setActivityCardOpen}
        //       case={theCase}
        //       activity={selectedActivity}
        //       dataFlag={dataFlag}
        //       setDataFlag={setDataFlag}
        //     ></ContentChange>
        //   );

        case "Business Assessment":
          return (
            <BusinessNeedsAssessment
              setCardOpen={setActivityCardOpen}
              case={theCase}
              activity={activity}
              dataFlag={dataFlag}
              setDataFlag={setDataFlag}
            ></BusinessNeedsAssessment>
          );

        case "Business Assessment (edit)":
          return (
            <ContentChange
              setCardOpen={setActivityCardOpen}
              case={theCase}
              activity={selectedActivity}
              dataFlag={dataFlag}
              setDataFlag={setDataFlag}
            ></ContentChange>
          );

        case "Business Action Plan":
          return (
            <BusinessActionPlan
              setCardOpen={setActivityCardOpen}
              case={theCase}
              activity={activity}
              dataFlag={dataFlag}
              setDataFlag={setDataFlag}
            ></BusinessActionPlan>
          );

        case "Business Action Plan (edit)":
          return (
            <ContentChange
              setCardOpen={setActivityCardOpen}
              case={theCase}
              activity={selectedActivity}
              dataFlag={dataFlag}
              setDataFlag={setDataFlag}
            ></ContentChange>
          );

        case "Referral (edit)":
          return (
            <ContentChange
              setCardOpen={setActivityCardOpen}
              case={theCase}
              activity={selectedActivity}
              dataFlag={dataFlag}
              setDataFlag={setDataFlag}
            ></ContentChange>
          );

        case "Edit Firm":
          return (
            <ContentChange
              setCardOpen={setActivityCardOpen}
              case={theCase}
              activity={selectedActivity}
              dataFlag={dataFlag}
              setDataFlag={setDataFlag}
            ></ContentChange>
          );

        case "Email Communication":
          return (
            <EmailViewer
              setEditorOpen={setActivityCardOpen}
              activity={selectedActivity}
            ></EmailViewer>
          );

        case "Referral Notice":
          return (
            <EmailViewer
              setEditorOpen={setActivityCardOpen}
              activity={selectedActivity}
            ></EmailViewer>
          );

        case "B2B Client Survey Email":
          return (
            <EmailViewer
              setEditorOpen={setActivityCardOpen}
              activity={selectedActivity}
            ></EmailViewer>
          );

        case "B2B Provider Survey Email":
          return (
            <EmailViewer
              setEditorOpen={setActivityCardOpen}
              activity={selectedActivity}
            ></EmailViewer>
          );

        case "Referral Survey":
          return (
            <EmailViewer
              setEditorOpen={setActivityCardOpen}
              activity={selectedActivity}
            ></EmailViewer>
          );

        case "Referral Survey Email":
          return (
            <EmailViewer
              setEditorOpen={setActivityCardOpen}
              activity={selectedActivity}
            ></EmailViewer>
          );

        case "Provider Referral Initiated":
          return (
            <EmailViewer
              setEditorOpen={setActivityCardOpen}
              activity={selectedActivity}
            ></EmailViewer>
          );

        case "Providers Referral Email":
          return (
            <EmailViewer
              setEditorOpen={setActivityCardOpen}
              activity={selectedActivity}
            ></EmailViewer>
          );

        case "Provider Referral Notice Email":
          return (
            <EmailViewer
              setEditorOpen={setActivityCardOpen}
              activity={selectedActivity}
            ></EmailViewer>
          );

        case "Event Invitation":
          return (
            <EmailViewer
              setEditorOpen={setActivityCardOpen}
              activity={selectedActivity}
            ></EmailViewer>
          );

        default:
          return (
            <Interaction
              displayTitle={"Edit Interaction"}
              setCardOpen={setActivityCardOpen}
              user={user}
              firm={firm}
              case={theCase}
              dataFlag={dataFlag}
              setDataFlag={setDataFlag}
              activity={selectedActivity}
            ></Interaction>
          );
      }
    }
  };

  const FlowCardModalContent = (title: string) => {
    switch (title) {
      case "INITIATE CONTACT WITH OUTREACH":
        return (
          <InitialContact
            setCardOpen={setCardOpen}
            caseID={caseID}
            activity={activity}
            dataFlag={dataFlag}
            setDataFlag={setDataFlag}
          ></InitialContact>
        );

      // case "ASK DISCOVERY QUESTIONS and RECORD ENVIRONMENTAL INFORMATION":
      //   return (
      //     <DiscoveryQuestions
      //       setCardOpen={setCardOpen}
      //       case={theCase}
      //       activity={activity}
      //       dataFlag={dataFlag}
      //       setDataFlag={setDataFlag}
      //     ></DiscoveryQuestions>
      //   );

      case "CONDUCT BUSINESS NEEDS ASSESSMENT":
        return (
          <BusinessNeedsAssessment
            setCardOpen={setCardOpen}
            case={theCase}
            activity={activity}
            dataFlag={dataFlag}
            setDataFlag={setDataFlag}
          ></BusinessNeedsAssessment>
        );

      case "CREATE ACTION PLAN":
        return (
          <BusinessActionPlan
            setCardOpen={setCardOpen}
            case={theCase}
            activity={activity}
            dataFlag={dataFlag}
            setDataFlag={setDataFlag}
          ></BusinessActionPlan>
        );

      case "Interaction":
        return (
          <Interaction
            setCardOpen={setCardOpen}
            user={user}
            firm={firm}
            case={theCase}
            dataFlag={dataFlag}
            setDataFlag={setDataFlag}
          ></Interaction>
        );
      default:
        break;
    }
  };

  const ReferralDetailList = () => {
    return referrals ? (
      <>
        {referrals.map((referral: any, index: number) => {
          return (
            <Box
              key={index}
              sx={{
                backgroundColor: "white",
                maxWidth: "98%",
                margin: "auto",
                marginBottom: "25px",
                borderRadius: "5px",
                minHeight: "400px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
              }}
            >
              <Referral
                setProviderSurveyOpen={setProviderSurveyOpen}
                setClientSurveyOpen={setClientSurveyOpen}
                setReferralNoticeOpen={setReferralNoticeOpen}
                setReferralNoticeContent={setReferralNoticeContent}
                providerReferralNoticeOpen={providerReferralNoticeOpen}
                setProviderReferralNoticeOpen={setProviderReferralNoticeOpen}
                setOpenReferral={setOpenReferral}
                setActivityCardOpen={setActivityCardOpen}
                setOpenActivityID={setOpenActivityID}
                setSelectedProvider={setSelectedProvider}
                user={user}
                referral={referral}
                dataFlag={dataFlag}
                setDataFlag={setDataFlag}
                activity={activity}
                case={theCase}
                firm={firm}
              ></Referral>
            </Box>
          );
        })}
      </>
    ) : (
      <></>
    );
  };

  const Remove2ndBfc = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/cases/remove2ndBfc`,
        {
          caseId: caseID,
        },
        axiosConfig
      )
      .then((res: any) => {
        setDataFlag(!dataFlag);
      });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          maxWidth: "98%",
          margin: "auto",
          marginBottom: "20px",
          borderRadius: "5px",
          minHeight: "400px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
        }}
      >
        <Stack
          sx={{
            color: "white",
            backgroundColor: "#0F95B3",
            borderRadius: "5px 5px 0 0",
            height: "50px",
            marginBottom: "30px",
            width: "100%",
            justifyContent: "center",
          }}
          direction={"row"}
          alignItems={"center"}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "36px" }}>
            {firm["Company Name"]}{" "}
          </Typography>
          <Typography sx={{ fontSize: "20px" }}>
            {firm["do_not_contact"] ? "(Do Not Contact)" : ""}{" "}
          </Typography>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Stack sx={{ padding: "10px" }}>
              <hr />
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Activities
              </Typography>
              <TableContainer
                component={"table"}
                sx={{ height: "340px", overflowY: "auto", display: "block" }}
              >
                <TableBody sx={{ display: "table", width: "100%" }}>
                  {activity.map((activityItem: any, index: number) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        align="left"
                        onClick={() => {
                          if (
                            ignoredActivityTypes.includes(
                              activityItem.activity_type
                            )
                          ) {
                          } else {
                            setActivityCardOpen(true);
                            setOpenActivityID(activityItem._id);
                          }
                        }}
                        sx={{
                          textDecoration: "underline",
                          color: "#9E1F63FF",
                          cursor: "pointer",
                        }}
                      >
                        {activityItem.user_id === theCase.user ? (
                          <p>{caseTypeMap(activityItem["activity_type"])}</p>
                        ) : (
                          <Tooltip
                            title={
                              initialMap[activityItem.user_id]
                                ? initialMap[activityItem.user_id]["full"]
                                : ""
                            }
                          >
                            <p>
                              {initialMap[activityItem.user_id]
                                ? initialMap[activityItem.user_id]["init"]
                                : ""}
                              --
                              {caseTypeMap(activityItem["activity_type"])}
                            </p>
                          </Tooltip>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {moment(new Date(activityItem["created_at"])).format(
                          "YYYY-MM-DD"
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </TableContainer>
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="h6" sx={{ display: { md: "none" } }}>
              Process Flow
            </Typography>
            <Grid container spacing={2} sx={{ padding: "10px" }}>
              <Grid item xs={12} md={3}>
                <FlowCard
                  displayTitle={"CASE CREATED"}
                  title={"CREATE CLIENT VISIT CASE"}
                  subtitle={
                    "You are the BFC working with this firm, assigned via a Firm Visit Plan"
                  }
                  type={"Case Created"}
                  activity={activity}
                  setCardOpen={() => {}}
                  setOpenCardTitle={setOpenCardTitle}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FlowCard
                  displayTitle={"INITIATE CONTACT"}
                  title={"INITIATE CONTACT WITH OUTREACH"}
                  subtitle={
                    "Outreach to the firm via email, phone or in- person"
                  }
                  type={"Initial Contact"}
                  activity={activity}
                  setCardOpen={setCardOpen}
                  setOpenCardTitle={setOpenCardTitle}
                />
              </Grid>
              {/* <Grid item xs={12} md={3}>
                <FlowCard
                  disable={true}
                  displayTitle={"OBTAIN DISCOVERY INFO"}
                  title={
                    "ASK DISCOVERY QUESTIONS and RECORD ENVIRONMENTAL INFORMATION"
                  }
                  subtitle={"Learn about firm's current situation"}
                  type={"Discovery Questions"}
                  activity={activity}
                  setCardOpen={setCardOpen}
                  setOpenCardTitle={setOpenCardTitle}
                />
              </Grid> */}
              <Grid item xs={12} md={3}>
                <FlowCard
                  displayTitle={"DO NEEDS ASSESSMENT"}
                  title={"CONDUCT BUSINESS NEEDS ASSESSMENT"}
                  subtitle={"Assess firm's needs"}
                  type={"Business Assessment"}
                  activity={activity}
                  setCardOpen={setCardOpen}
                  setOpenCardTitle={setOpenCardTitle}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <FlowCard
                  displayTitle={"CREATE ACTION PLAN"}
                  title={"CREATE ACTION PLAN"}
                  subtitle={"Setup goals and milestones to address needs"}
                  type={"Business Action Plan"}
                  activity={activity}
                  setCardOpen={setCardOpen}
                  setOpenCardTitle={setOpenCardTitle}
                />
              </Grid>
              {/* <Grid item xs={12} md={3}>
                <FlowCard
                  displayTitle={"MAKE REFERRALS"}
                  title={"MAKE REFERRALS"}
                  subtitle={
                    "Connect firm with the best-fit Resources partners and B2B Providers"
                  }
                  type={"Referral"}
                  activity={activity}
                  setCardOpen={setCardOpen}
                  setOpenCardTitle={setOpenCardTitle}
                  navigateUrl={"/dashboard/resources"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FlowCard
                  disable={true}
                  displayTitle={"ASSESS REFERRALS"}
                  title={"CAPTURE AND RECORD RESULTS OF REFERRALS"}
                  subtitle={
                    "Capture results of referrals and Action Plan activities"
                  }
                  type={"referral_result"}
                  activity={activity}
                  setCardOpen={setCardOpen}
                  setOpenCardTitle={setOpenCardTitle}
                />
              </Grid> */}
              {/* <Grid item xs={12} md={3}>
                <FlowCard
                  disable={true}
                  title={"FOLLOW UP WITH CLIENT"}
                  subtitle={"To determine next steps, if necessary"}
                  type={"follow_up"}
                  activity={activity}
                  setCardOpen={setCardOpen}
                  setOpenCardTitle={setOpenCardTitle}
                />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "80%", marginTop: "10px" }}
                onClick={() => {
                  setCardOpen(true);
                  setOpenCardTitle("Interaction");
                }}
              >
                Interaction
              </Button>

              <Button
                variant="contained"
                color="success"
                sx={{ width: "80%" }}
                onClick={() => {
                  window.open(`/firmReportPdf?id=${theCase._id}`, "_blank");
                }}
              >
                Client Detail
              </Button>

              <Box>
                <Stack
                  sx={{
                    padding: "10px",
                    alignItems: "center",
                  }}
                  spacing={1}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: { md: "left" } }}
                  >
                    Created Date: {moment(theCase.date).format("YYYY-MM-DD")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: { md: "left" } }}
                  >
                    Created By: {creator.firstName} {creator.lastName}
                  </Typography>
                  {theCase.user_2nd && (
                    <Typography
                      variant="body1"
                      sx={{ textAlign: { md: "left" } }}
                    >
                      2nd BFC:{secondaryBfc.firstName} {secondaryBfc.lastName}
                    </Typography>
                  )}

                  {theCase.user_2nd ? (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ width: "100%" }}
                      onClick={Remove2ndBfc}
                    >
                      UNASSIGN 2ND BFC
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ width: "100%" }}
                      onClick={() => setAddBfcOpen(true)}
                    >
                      ADD 2ND BFC
                    </Button>
                  )}

                  {theCase.status === "open" && (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ width: "100%" }}
                      onClick={() => {
                        setConfirmMessage("Are you sure to close the Case?");
                        setConfirmOpen(true);
                        setConfirmAction(() => () => closeCase(caseID));
                      }}
                    >
                      Close Case
                    </Button>
                  )}
                  {theCase.status === "open" && (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ width: "100%" }}
                      onClick={() => setReassignClientOpen(true)}
                    >
                      Reassign Client
                    </Button>
                  )}
                  {theCase.status === "completed" && (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ width: "100%" }}
                      onClick={() => reopenCase(caseID)}
                    >
                      Reopen Case
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="warning"
                    sx={{ width: "100%" }}
                    onClick={() => {
                      setMergeCaseOpen(true);
                      setTimeout(() => {
                        setMessageOpen(true);
                        setMessageTitle("Merge Case");
                        setMessageBody(
                          "Attention! All the activities and referrals of the selected case will be moved to the current case, also will the client information."
                        );
                      }, 300);
                    }}
                  >
                    Merge Case
                  </Button>

                  <Button
                    variant="contained"
                    color="error"
                    sx={{ width: "100%" }}
                    onClick={() => {
                      setConfirmMessage("Are you sure to delete the Case?");
                      setConfirmOpen(true);
                      setConfirmAction(() => () => deleteCase(caseID));
                    }}
                  >
                    Unassign
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      {referrals.length > 0 ? <ReferralDetailList></ReferralDetailList> : <></>}

      <Box
        sx={{
          backgroundColor: "white",
          maxWidth: "98%",
          margin: "auto",
          borderRadius: "5px",
          minHeight: "400px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
        }}
      >
        <MiNiFirmReport
          firm={firm}
          editedFirm={editedFirm}
          setEditedFirm={setEditedFirm}
          editedGrant={editedGrant}
          setEditedGrant={setEditedGrant}
          user={user}
          dataFlag={dataFlag}
          setDataFlag={setDataFlag}
          setDirectEmailOpen={setDirectEmailOpen}
        />
      </Box>
      <ThriveModal
        open={cardOpen}
        // setOpen={setCardOpen}
        contentComponent={FlowCardModalContent(openCardTitle)}
      ></ThriveModal>
      <ThriveModal
        open={activityCardOpen}
        height={750}
        // setOpen={setActivityCardOpen}
        contentComponent={ActivityModalContent(openActivityID)}
      ></ThriveModal>

      <ThriveModal
        height={750}
        open={referralNoticeOpen}
        // setOpen={setCardOpen}
        contentComponent={
          <EmailEditor
            theSubject={
              "Business Forward - Referral Notice for " + firm["Company Name"]
            }
            setOpen={setReferralNoticeOpen}
            theCase={theCase}
            theFirm={firm}
            theReferral={referralNoticeContent}
            editorTitle={"Resource Referral Notice"}
            bodyGenerator={ResourceReferralNotice}
            setMessageOpen={setMessageOpen}
            setMessageTitle={setMessageTitle}
            setMessageBody={setMessageBody}
            theBFC={{}}
            theSelectedEvent={{}}
            theSelectedProvider={{}}
          ></EmailEditor>
        }
      ></ThriveModal>

      {/* Modal for provider referral notice */}
      <ThriveModal
        height={750}
        open={providerReferralNoticeOpen}
        // setOpen={setCardOpen}
        contentComponent={
          <EmailEditor
            theSubject={
              "Business Forward - Provider for " + firm["Company Name"]
            }
            setOpen={setProviderReferralNoticeOpen}
            theCase={theCase}
            theFirm={firm}
            theReferral={referralNoticeContent}
            editorTitle={"Provider 2 hours Referral Notice"}
            bodyGenerator={Provider2hoursReferral}
            setMessageOpen={setMessageOpen}
            setMessageTitle={setMessageTitle}
            setMessageBody={setMessageBody}
            theBFC={{}}
            theSelectedEvent={{}}
            theSelectedProvider={{}}
          ></EmailEditor>
        }
      ></ThriveModal>

      {/* Modal for B2B client survey */}
      <ThriveModal
        height={750}
        open={clientSurveyOpen}
        // setOpen={setCardOpen}
        contentComponent={
          <EmailEditor
            theSubject={"Business Forward - B2B Client Survey"}
            setOpen={setClientSurveyOpen}
            theCase={theCase}
            theFirm={firm}
            theBFC={creator}
            theReferral={referralNoticeContent}
            editorTitle={"Client Survey - B2B Provider"}
            bodyGenerator={ProviderReferralClientSurvey}
            setMessageOpen={setMessageOpen}
            setMessageTitle={setMessageTitle}
            setMessageBody={setMessageBody}
            theSelectedEvent={{}}
            theSelectedProvider={{}}
          ></EmailEditor>
        }
      ></ThriveModal>
      {/* Modal for B2B provider survey */}
      <ThriveModal
        height={750}
        open={providerSurveyOpen}
        // setOpen={setCardOpen}
        contentComponent={
          <EmailEditor
            theSubject={"Business Forward - B2B Provider Survey"}
            setOpen={setProviderSurveyOpen}
            theCase={theCase}
            theFirm={firm}
            theReferral={referralNoticeContent}
            editorTitle={"Provider Survey - B2B Provider"}
            bodyGenerator={ProviderReferralProviderSurvey}
            setMessageOpen={setMessageOpen}
            setMessageTitle={setMessageTitle}
            setMessageBody={setMessageBody}
            theBFC={{}}
            theSelectedEvent={{}}
            theSelectedProvider={selectedProvider}
          ></EmailEditor>
        }
      ></ThriveModal>
      {/* Modal for direct email */}
      <ThriveModal
        height={750}
        open={directEmailOpen}
        // setOpen={setCardOpen}
        contentComponent={
          <EmailEditor
            theSubject={""}
            setOpen={setDirectEmailOpen}
            theCase={theCase}
            theFirm={firm}
            theReferral={{}}
            editorTitle={"Direct Email"}
            bodyGenerator={DirectEmailEmptyBody}
            setMessageOpen={setMessageOpen}
            setMessageTitle={setMessageTitle}
            setMessageBody={setMessageBody}
            theBFC={{}}
            theSelectedEvent={{}}
            theSelectedProvider={{}}
          ></EmailEditor>
        }
      ></ThriveModal>

      <ThriveModal
        open={addBfcOpen}
        contentComponent={
          <Add2ndBfc
            setAddBfcOpen={setAddBfcOpen}
            currUser={creator}
            caseId={caseID}
          />
        }
      ></ThriveModal>
      <ThriveModal
        open={reassignClientOpen}
        contentComponent={
          <ReassignClient
            setReassignClientOpen={setReassignClientOpen}
            currUser={creator}
            caseId={caseID}
          />
        }
      ></ThriveModal>
      <Confirm
        isOpen={confirmOpen}
        setCardOpen={setConfirmOpen}
        message={confirmMessage}
        confirmAction={confirmAction}
      />
      <ThriveModal
        open={messageOpen}
        setOpen={setMessageOpen}
        closeByClick={true}
        height={250}
        width={500}
        contentComponent={
          <NoticeDialog
            title={messageTitle}
            body={messageBody}
            setOpen={setMessageOpen}
            dataFlag={dataFlag}
            setDataFlag={setDataFlag}
          ></NoticeDialog>
        }
      ></ThriveModal>
      <ThriveModal
        open={mergeCaseOpen}
        setOpen={setMergeCaseOpen}
        closeByClick={true}
        contentComponent={
          <MergeCase
            currentCase={theCase}
            setOpen={setMergeCaseOpen}
          ></MergeCase>
        }
      ></ThriveModal>
    </>
  );
};

export default Case;
