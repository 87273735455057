import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useContext, useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { UserType } from "../../interfaces";
import { Editor } from "@tinymce/tinymce-react";
import { StyledButton } from "./Buttons";
import { useNavigate } from "react-router-dom";
import {
  afterTemplate,
  beforeTemplate,
} from "./EmailBodies/DirectEmailEmptyBody";
import { parse } from "node-html-parser";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";

const GetPrimaryContact = (firm: any) => {
  if (firm.additionalContacts) {
    //Find the primary contact
    const primaryContact = firm.additionalContacts.find(
      (contact: any) => contact.isDefault === true
    );
    if (primaryContact) {
      return { Contact: primaryContact.Contact, Email: primaryContact.Email };
    } else {
      return { Contact: firm["Contact"], Email: firm["Email"] };
    }
  } else {
    return { Contact: firm["Contact"], Email: firm["Email"] };
  }
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface EmailEditorProps {
  setOpen: any;
  setMessageOpen: any;
  setMessageTitle: any;
  setMessageBody: any;
  theBFC: any;
  theCase: any;
  theFirm: any;
  theReferral: any;
  theSubject: string;
  editorTitle: string;
  theSelectedProvider: any;
  theSelectedEvent: any;
  bodyGenerator: any;
  checkedCases?: any;
  displayCases?: any;
}

const EmailEditor: React.FC<EmailEditorProps> = ({
  setOpen,
  setMessageOpen,
  setMessageTitle,
  setMessageBody,
  theBFC,
  theCase,
  theFirm,
  theReferral,
  theSubject,
  editorTitle,
  theSelectedProvider,
  theSelectedEvent,
  bodyGenerator,
  checkedCases,
  displayCases,
}) => {
  const navigate = useNavigate();
  const { user }: { user: UserType } = useContext(UserContext);
  const [selectedProvider, setSelectedProvider] = useState<any>(
    theSelectedProvider ? theSelectedProvider : {}
  );
  const [selectedBFC, setSelectedBFC] = useState<any>(theBFC);
  const [selectedResource, setSelectedResource] = useState<any>({});
  const [templates, setTemplates] = useState<any>([]);
  const [subject, setSubject] = useState(
    theSubject
      ? theSubject
      : "Business Forward Consultant - " + user.firstName + " " + user.lastName
  );
  const [currentTemplate, setCurrentTemplate] = useState<any>({
    _id: "",
    template_name: "",
    subject:
      "Business Forward Consultant - " + user.firstName + " " + user.lastName,
    htmlContent: "",
  });
  const [selectedEvent, setSelectedEvent] = useState(
    theSelectedEvent
      ? theSelectedEvent
      : {
          url: "",
          name: { text: "" },
          logo: { url: "" },
          locationInfo: {
            address: {
              address_1: "",
              address_2: "",
              city: "",
              region: "",
              postal_code: "",
            },
            localized_address_display: "",
          },
          start: { local: "" },
          end: { local: "" },
        }
  );
  const [attachments, setAttachments] = useState<any>([]);

  const GenerateEmailConfig = (editor_title: string) => {
    if (!subject) {
      setSubject(
        "Business Forward Consultant - " + user.firstName + " " + user.lastName
      );
    }
    const primaryContact = GetPrimaryContact(theFirm);
    switch (editor_title) {
      case "Provider 2 hours Referral Notice":
        return {
          activity_type: "Provider Referral Notice Email",
          to: `${primaryContact["Email"]}`,
          cc: `${user["email"]},${selectedBFC["email"]},noguezc@oaklandthrive.org,${selectedProvider["email"]},careyr@oaklandthrive.org`,
          bcc: `support@thrive-drive.org`,
          replyTo: [
            { name: "", email: user.email },
            { name: "", email: "support@thrive-drive.org>" },
          ],
          subject: subject,
          attachments: attachments,
          case_id: theCase["_id"],
          referral_id: theReferral["_id"],
          from: user._id as string,
          fromEmail: user.email,
          fromBFC: user.firstName + " " + user.lastName,
          toClient: theFirm["_id"],
          toClientContact: primaryContact["Contact"],
          toClientEmail: primaryContact["Email"],
          toProvider: selectedProvider["id"],
          toProviderContact:
            selectedProvider["firstName"] + " " + selectedProvider["lastName"],
          toProviderEmail: selectedProvider["email"],
        };

      case "Resource Referral Notice":
        return {
          activity_type: "Referral Notice",
          to: `${primaryContact["Email"]}, ${selectedResource["email"]}`,
          cc: `${user["email"]}`,
          bcc: `support@thrive-drive.org`,
          replyTo: [
            { name: "", email: user.email },
            { name: "", email: theFirm["Email"] },
            { name: "", email: selectedResource["email"] },
            { name: "", email: "support@thrive-drive.org" },
          ],
          subject: subject,
          case_id: theCase["_id"],
          referral_id: theReferral["_id"],
          attachments: attachments,
          from: user._id as string,
          fromEmail: user.email,
          fromBFC: user.firstName + " " + user.lastName,
          toClient: theFirm["_id"],
          toClientContact: primaryContact["Contact"],
          toClientEmail: primaryContact["Email"],
          toResource: selectedResource["id"],
          toResourceContact:
            selectedResource["firstName"] + " " + selectedResource["lastName"],
          toResourceEmail: selectedResource["email"],
        };

      case "Client Survey - Resource":
        return {
          activity_type: "Referral Survey Email",
          to: `${primaryContact["Email"]}`,
          cc: `${user["email"]}`,
          bcc: `support@thrive-drive.org`,
          replyTo: [
            { name: "", email: user.email },
            { name: "", email: "support@thrive-drive.org>" },
          ],
          subject: subject,
          case_id: theCase["_id"],
          referral_id: theReferral["_id"],
          attachments: attachments,
          from: user._id as string,
          fromEmail: user.email,
          fromBFC: user.firstName + " " + user.lastName,
          toClient: theFirm["_id"],
          toClientContact: primaryContact["Contact"],
          toClientEmail: primaryContact["Email"],
          toResource: selectedResource["id"],
          toResourceContact:
            selectedResource["firstName"] + " " + selectedResource["lastName"],
          toResourceEmail: selectedResource["email"],
        };

      case "Providers Referral Email":
        return {
          activity_type: "Providers Referral Email",
          to: `${primaryContact["Email"]}`,
          cc: `${user["email"]},${selectedBFC["email"]},noguezc@oaklandthrive.org,careyr@oaklandthrive.org`,
          bcc: `support@thrive-drive.org`,
          replyTo: [
            { name: "", email: user.email },
            { name: "", email: "support@thrive-drive.org>" },
          ],
          subject: subject,
          case_id: theCase["_id"],
          attachments: attachments,
          from: user._id as string,
          fromEmail: user.email,
          fromBFC: user.firstName + " " + user.lastName,
          toClient: theFirm["_id"],
          toClientContact: primaryContact["Contact"],
          toClientEmail: primaryContact["Email"],
        };

      case "Client Survey - B2B Provider":
        return {
          activity_type: "B2B Client Survey Email",
          to: `${primaryContact["Email"]}`,
          cc: `${user["email"]},${selectedBFC["email"]},noguezc@oaklandthrive.org`,
          bcc: `support@thrive-drive.org`,
          replyTo: [
            { name: "", email: user.email },
            { name: "", email: "support@thrive-drive.org>" },
          ],
          subject: subject,
          case_id: theCase["_id"],
          referral_id: theReferral["_id"],
          attachments: attachments,
          from: user._id as string,
          fromEmail: user.email,
          fromBFC: user.firstName + " " + user.lastName,
          toClient: theFirm["_id"],
          toClientContact: primaryContact["Contact"],
          toClientEmail: primaryContact["Email"],
          toResource: selectedResource["id"],
          toResourceContact:
            selectedResource["firstName"] + " " + selectedResource["lastName"],
          toResourceEmail: selectedResource["email"],
          toProvider: selectedProvider["id"],
          toProviderContact:
            selectedProvider["firstName"] + " " + selectedProvider["lastName"],
          toProviderEmail: selectedProvider["email"],
        };
      case "Provider Survey - B2B Provider":
        return {
          activity_type: "B2B Provider Survey Email",
          to: `${selectedProvider["email"]}`,
          cc: `${user["email"]}, noguezc@oaklandthrive.org`,
          bcc: `support@thrive-drive.org`,
          replyTo: [
            { name: "", email: user.email },
            { name: "", email: "support@thrive-drive.org>" },
          ],
          subject: subject,
          case_id: theCase["_id"],
          referral_id: theReferral["_id"],
          attachments: attachments,
          from: user._id as string,
          fromEmail: user.email,
          fromBFC: user.firstName + " " + user.lastName,
          toClient: theFirm["_id"],
          toClientContact: primaryContact["Contact"],
          toClientEmail: primaryContact["Email"],
          toResource: selectedResource["id"],
          toResourceContact:
            selectedResource["firstName"] + " " + selectedResource["lastName"],
          toResourceEmail: selectedResource["email"],
          toProvider: selectedProvider["id"],
          toProviderContact:
            selectedProvider["firstName"] + " " + selectedProvider["lastName"],
          toProviderEmail: selectedProvider["email"],
        };

      case "Direct Email":
        return {
          activity_type: "Email Communication",
          to: `${primaryContact["Email"]}`,
          cc: `${user["email"]}`,
          bcc: `support@thrive-drive.org`,
          replyTo: [{ name: "", email: user.email }],
          subject: subject,
          case_id: theCase ? theCase["_id"] : "",
          attachments: attachments,
          from: user._id as string,
          fromEmail: user.email,
          fromBFC: user.firstName + " " + user.lastName,
          toClient: theFirm["_id"],
          toClientContact: primaryContact["Contact"],
          toClientEmail: primaryContact["Email"],
        };
      default:
        return {
          activity_type: "Unknown Activity Type -- EmailEditor.tsx",
          to: `${user["email"]}`,
          cc: `support@thrive-drive.org`,
          replyTo: `support@thrive-drive.org`,
          subject: "Error - Unknown Activity Type -- EmailEditor.tsx",
          case_id: theCase["_id"],
          referral_id: theReferral["_id"],
          from: user._id as string,
          fromEmail: user.email,
          fromBFC: user.firstName + " " + user.lastName,
          toClient: theFirm["_id"],
          toClientContact: primaryContact["Contact"],
          toClientEmail: primaryContact["Email"],
          toResource: selectedResource["id"],
          toResourceContact:
            selectedResource["firstName"] + " " + selectedResource["lastName"],
          toResourceEmail: selectedResource["email"],
          toProvider: selectedProvider["id"],
          toProviderContact:
            selectedProvider["firstName"] + " " + selectedProvider["lastName"],
          toProviderEmail: selectedProvider["email"],
        };
    }
  };

  const [emailConfig, setEmailConfig] = useState<any>(
    GenerateEmailConfig(editorTitle)
  );
  const originalHtmlbody = bodyGenerator(
    user,
    theFirm,
    theCase,
    theReferral,
    selectedProvider,
    selectedResource,
    selectedEvent,
    currentTemplate.htmlContent
  );
  const [htmlBody, setHtmlBody] = useState(originalHtmlbody);
  // const navigate = useNavigate();
  const editorRef = useRef(null);
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/email/template`, axiosConfig)
      .then((res) => {
        setTemplates(res.data.email_tamplates);
      });
  }, []);

  useEffect(() => {
    setEmailConfig(GenerateEmailConfig(editorTitle));
  }, [selectedResource, selectedBFC, subject, attachments]);

  useEffect(() => {
    if (Object.keys(theBFC).length === 0) {
      if (editorTitle !== "Event Invitation") {
        axios
          .get(
            `${process.env.REACT_APP_API_DOMAIN}/user?case_id=${theCase._id}`,
            axiosConfig
          )
          .then((res) => {
            setSelectedBFC(res.data.user);
          });
      }
    }
  }, [theBFC]);

  useEffect(() => {
    const fetchReferral = async () => {
      let res: any = { data: {} };
      if (theReferral.resource_id) {
        res = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/resources?resource_id=${theReferral.resource_id}`,
          axiosConfig
        );
        setSelectedResource(res.data.resource);
      }
      if (theReferral.provider_id) {
        res = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/resources?provider_id=${theReferral.provider_id}`,
          axiosConfig
        );
        setSelectedProvider(res.data.provider);
      }
      setHtmlBody(
        bodyGenerator(
          user,
          theFirm,
          theCase,
          theReferral,
          res.data.provider,
          res.data.resource,
          selectedEvent,
          currentTemplate.htmlContent
        )
      );
    };

    if (theReferral) {
      fetchReferral();
    }
  }, [theReferral]);

  const handleEmailSend = () => {
    interface EmailConfig {
      activity_type: string;
      to: string;
      cc?: string;
      bcc?: string;
      replyTo?: string[];
      subject?: string;
      case_id?: string;
      referral_id?: string;
      attachments?: any;
      from?: string;
      fromEmail?: string;
      fromBFC?: string;
      toClient?: string;
      toClientContact?: string;
      toClientEmail?: string;
      toResource?: string;
      toResourceContact?: string;
      toResourceEmail?: string;
      toProvider?: string;
      toProviderContact?: string;
      toProviderEmail?: string;
    }

    if (editorRef.current) {
      //@ts-ignore
      const htmlString = editorRef.current.getContent();

      if (editorTitle === "Event Invitation") {
        let eventTargets = checkedCases.map((checkedCase: any) => {
          return displayCases[checkedCase];
        });

        //Clean up eventTargets
        eventTargets = eventTargets.filter(function (element: any) {
          return element !== undefined;
        });

        eventTargets = eventTargets.map((event: any) => {
          return event["firm"];
        });

        axios
          .post(
            `${process.env.REACT_APP_API_DOMAIN}/email/event_invitation`,
            {
              activity_type: "Event Invitation",
              email: {
                htmlContent: htmlString,
                tos: eventTargets,
                from: user._id,
                fromEmail: user.email,
                fromBFC: user.firstName + " " + user.lastName,
              },
            },
            axiosConfig
          )
          .then((res) => {
            if (res.status === 200) {
              setOpen(false);
              setMessageOpen(true);
            } else {
              console.log("API Error");
            }
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_API_DOMAIN}/email/referral`,
            {
              activity_type: emailConfig["activity_type"],
              email: {
                htmlContent: htmlString,
                to: emailConfig["to"],
                cc: emailConfig["cc"],
                bcc: emailConfig["bcc"],
                replyTo: emailConfig["replyTo"],
                subject: emailConfig["subject"],
                case_id: emailConfig["case_id"],
                referral_id: emailConfig["referral_id"],
                attachments: emailConfig["attachments"],
                from: emailConfig["from"],
                fromEmail: emailConfig["fromEmail"],
                fromBFC: emailConfig["fromBFC"],
                toClient: emailConfig["toClient"],
                toClientContact: emailConfig["toClientContact"],
                toClientEmail: emailConfig["toClientEmail"],
                toResource: emailConfig["toResource"],
                toResourceContact: emailConfig["toResourceContact"],
                toResourceEmail: emailConfig["toResourceEmail"],
                toProvider: emailConfig["toProvider"],
                toProviderContact: emailConfig["toProviderContact"],
                toProviderEmail: emailConfig["toProviderEmail"],
              },
            },
            axiosConfig
          )
          .then((res) => {
            if (res.status === 200) {
              setOpen(false);
              setMessageOpen(true);
              if (res.data.status === "error") {
                setMessageTitle("Email Sending Fails");
                setMessageBody(res.data.msg);
              } else {
                setMessageTitle("Email Sent Successfully");
                setMessageBody("");
              }

              if (editorTitle === "Providers Referral Email") {
                let email_id = res.data.email_id;
                for (let provider of theSelectedProvider) {
                  let referral = {
                    _id: "",
                    case_id: theCase["_id"],
                    provider_id: provider["id"],
                    firm_id: theFirm["_id"],
                  };

                  axios.post(
                    `${process.env.REACT_APP_API_DOMAIN}/referral`,
                    {
                      activity_type: "Provider Referral Initiated",
                      referral: {
                        ...referral,
                        email_id: email_id,
                        referral_type: "Provider",
                      },
                    },
                    axiosConfig
                  );
                }
                setTimeout(() => {
                  setMessageOpen(false);
                  navigate(`/case?id=${theCase["_id"]}`);
                }, 1000);
              }
            } else {
              console.log("API Error");
            }
          });
      }
    }
  };

  const handleSaveTemplate = () => {
    if (editorRef.current) {
      //@ts-ignore
      const htmlString = editorRef.current.getContent();
      const root = parse(htmlString);
      const elementsWithRole = root.querySelectorAll("[role]");
      const roleName = "replace";
      const elementsWithSpecifiedRole = elementsWithRole.filter(
        (element) => element.getAttribute("role") === roleName
      );
      const targetElement = elementsWithSpecifiedRole[0];

      axios
        .post(
          `${process.env.REACT_APP_API_DOMAIN}/email/template`,
          {
            template: {
              ...currentTemplate,
              htmlContent: targetElement.outerHTML,
            },
          },
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            window.location.reload();
          } else {
            console.log("API Error");
          }
        });
    }
  };

  const handleSwitchTemplate = (e: any) => {
    setCurrentTemplate(
      templates.find((template: any) => template._id === e.target.value)
    );

    // //Get current body
    // let loadedBody = htmlbody.replace();
    setSubject(
      templates.find((template: any) => template._id === e.target.value).subject
    );
    let savedTemplate = templates.find(
      (template: any) => template._id === e.target.value
    ).htmlContent;
    let toSetHtmlBody =
      beforeTemplate(theFirm) + savedTemplate + afterTemplate(user, theFirm);

    setHtmlBody(toSetHtmlBody);
  };

  const handleDeleteTemplate = (id: string) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_DOMAIN}/email/template/${id}`,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        } else {
          console.log("API Error");
        }
      });
  };

  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    const token: string | undefined = Cookies.get("token");
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
      },
    };
    const result = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/upload/attachment`,
      formData,
      axiosConfig
    );
    console.log(result);
    if (
      attachments.find(
        (attachment: any) => attachment.name === result.data.attachmentName
      )
    ) {
      console.log("Attachment already exists");
    } else {
      setAttachments([
        ...attachments,
        {
          name: result.data.attachmentName,
          storageName: result.data.storageName,
          path: `/public/email/upload/` + result.data.storageName,
          download: `${process.env.REACT_APP_API_DOMAIN}/upload/attachment/${result.data.storageName}/${result.data.attachmentName}`,
        },
      ]);
    }
  };

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#9E1F63FF",
          padding: "3px",
          margin: 0,
          color: "white",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Typography sx={{ display: "inline-block" }} variant="h4">
          {editorTitle}
        </Typography>
        <Box
          sx={{ display: "inline", float: "right", cursor: "pointer" }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon sx={{ fontSize: 40 }}></CloseIcon>
        </Box>
      </Box>
      <Box sx={{ overflow: "auto", maxHeight: "680px" }}>
        {editorTitle === "Direct Email" && (
          <Stack
            direction={"row"}
            justifyContent="space-between"
            sx={{ margin: "10px" }}
          >
            <FormControl sx={{ width: "200px" }}>
              <InputLabel>Message Templates</InputLabel>
              <Select
                value={currentTemplate._id ? currentTemplate._id : ""}
                label="Message Templates"
                onChange={handleSwitchTemplate}
              >
                {templates.length > 0 &&
                  templates.map((template: any) => {
                    return (
                      <MenuItem key={template._id} value={template._id}>
                        {template.template_name}
                        {currentTemplate._id !== template._id && (
                          <DeleteIcon
                            style={{ marginLeft: "auto", color: "red" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleDeleteTemplate(template._id);
                              // console.log("delete " + template._id);
                            }}
                          />
                        )}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <TextField
              id="standard-basic"
              label="Template Title"
              variant="standard"
              value={currentTemplate.template_name}
              onChange={(e) =>
                setCurrentTemplate({
                  ...currentTemplate,
                  template_name: e.target.value,
                })
              }
            />
            <StyledButton
              variant="contained"
              sx={{
                backgroundColor: "#9E1F63FF",
                height: "55px",
              }}
              onClick={handleSaveTemplate}
            >
              Save as Template
            </StyledButton>
          </Stack>
        )}
        {editorTitle !== "Event Invitation" && (
          <Stack
            justifyContent="space-between"
            sx={{ margin: "10px", fontSize: "20px" }}
          >
            {/* <Typography>To: {emailConfig["to"]}</Typography> */}
            <TextField
              label="To:"
              variant="standard"
              value={emailConfig["to"]}
              onChange={(e) => {
                setEmailConfig({ ...emailConfig, to: e.target.value });
              }}
              sx={{ marginLeft: "10px", width: "60%" }}
            />

            <TextField
              label="Cc:"
              variant="standard"
              value={emailConfig["cc"]}
              onChange={(e) => {
                setEmailConfig({ ...emailConfig, cc: e.target.value });
              }}
              sx={{ marginLeft: "10px", width: "60%" }}
            />

            <TextField
              label="Subject:"
              variant="standard"
              value={currentTemplate.subject}
              onChange={(e) => {
                setCurrentTemplate({
                  ...currentTemplate,
                  subject: e.target.value,
                });
                setSubject(e.target.value);
              }}
              sx={{ marginLeft: "10px", width: "60%" }}
            />

            <Button
              sx={{
                color: "#9E1F63FF",
                width: "200px",
                marginTop: "20px",
                textAlign: "left",
              }}
              component="label"
            >
              Add Attachment
              <VisuallyHiddenInput
                type="file"
                onChange={(e: any) => {
                  handleFileUpload(e.target.files![0]);
                }}
              />
            </Button>
          </Stack>
        )}

        <Stack sx={{ margin: 0, marginLeft: "10px" }} spacing={0.5}>
          {attachments.map((attachment: any, index: number) => {
            return (
              <Card
                key={index}
                sx={{ backgroundColor: "#f1f1f1", maxWidth: "fit-content" }}
              >
                <ClearIcon
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setAttachments(
                      attachments.filter(
                        (att: any) => att.name !== attachment.name
                      )
                    );
                  }}
                ></ClearIcon>
                {attachment.name}
              </Card>
            );
          })}
        </Stack>

        <Box
          sx={{
            margin: "10px",
            border: "solid 1px #c4c4c4",
            borderRadius: "0 0 10px 10px",
            // overflow: "auto",
            height: "500px",
          }}
        >
          <Editor
            tinymceScriptSrc={`${process.env.REACT_APP_API_DOMAIN}/javascripts/tinymce/tinymce.min.js`}
            //@ts-ignore
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={htmlBody}
            init={{
              forced_root_block: "",
              height: 550,
              menubar: false,
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              relative_urls: false,
              remove_script_host: false,
              convert_urls: true,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          bottom: 20,
          width: "100%",
          textAlign: "center",
          position: "fixed",
        }}
      >
        <StyledButton
          variant="contained"
          sx={{
            width: "200px",
            fontSize: "20px",
            backgroundColor: "#9E1F63FF",
          }}
          onClick={handleEmailSend}
        >
          Send
        </StyledButton>
      </Box>
    </>
  );
};

export default EmailEditor;
